import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";

// PAGES
import Home from './Pages/Home';
import Blockchain from './Pages/Blockchain';
import NFT from './Pages/NFT';

// COMPONENTS
import Navbar from './Components/Navbar';
import MobileNav from './Components/MobileNav';
import CVFooter from './Components/footer';

  // HOME
  import HomeHero from './Components/home/HomeHero';
  import CVIntro from './Components//home/CVIntro';
  import HomeSlide3 from './Components/home/HomeSlide3';

  // BLOCKCHAIN
  import Slide4 from './Components/blockchain/slide4';
  import BlockchainHero from './Components/blockchain/Hero';

  // NFT
  import NFTHero from './Components/nft/Hero';
  import Aspect from './Components/nft/Aspect';


export default function App() {

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [showMobileNav, setShowMobileNav] = React.useState(false);
  const [currPage, setCurrPage] = React.useState(0);

  const handlePageChange = (index) => {
    setCurrPage(index);
    window.scrollTo({
      top: 0,
      bahvior: "instant"
    });
  }

  React.useEffect(() => {
    if (window.location.pathname === '/'){
      setCurrPage(1);
    }

    if (window.location.pathname === '/blockchain'){
      setCurrPage(2);
    }

    if (window.location.pathname === '/nft'){
      setCurrPage(3);
    }
  }, []);

    React.useEffect(() => {
        function watchWidth(){
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener("resize", watchWidth)
        return function(){
            window.removeEventListener("resize", watchWidth)
        }
    }, [windowWidth]);

  return (
    <BrowserRouter>
      <div className="text-white bg-primary-background pt-16 md:pt-20">
        <Navbar windowWidth={windowWidth} currPage={currPage} handlePageChange={handlePageChange} showMobileNav={showMobileNav} setShowMobileNav={setShowMobileNav}/>
        <MobileNav showMobileNav={showMobileNav} setShowMobileNav={setShowMobileNav} currPage={currPage} handlePageChange={handlePageChange}/>
        <Routes>
          <Route path="" element={<Home windowWidth={windowWidth} HomeHero={<HomeHero windowWidth={windowWidth}/>} CVIntro={<CVIntro windowWidth={windowWidth}/>} HomeSlide3={<HomeSlide3 windowWidth={windowWidth}/>}/>}/>
          <Route path="/blockchain" element={<Blockchain windowWidth={windowWidth} Hero={<BlockchainHero />} Slide4={<Slide4 windowWidth={windowWidth} />}/>}/>
          <Route path="/nft" element={<NFT windowWidth={windowWidth} Hero={<NFTHero windowWidth={windowWidth}/>} Aspect={<Aspect />}/>}/>
          <Route path='*' element={<Home windowWidth={windowWidth} HomeHero={<HomeHero windowWidth={windowWidth}/>} CVIntro={<CVIntro windowWidth={windowWidth}/>} HomeSlide3={<HomeSlide3 windowWidth={windowWidth}/>}/>}/>
        </Routes>
        <CVFooter />
      </div>
    </BrowserRouter>
  );
}
