import React from 'react';

// FOOTER ASSETS
import logo from '../assets/CYBERVOYCE full logo.png';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DiscordIcon from '../assets/discord-white-icon.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '../assets/x-social-media-white-icon.png';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function footer(){
    return(
        <footer className="h-fit pt-10 pb-16 flex font-Exo font-medium text-lg bg-secondary-bg2 relative">
                <div className="w-fit lg:w-full max-w-lg lg:max-w-none flex flex-col lg:flex-row items-center lg:justify-evenly mx-auto">
                    <img src={logo} alt="cyber voyce" className="w-4/5 max-w-sm prevent-select"/>

                    <div className="flex flex-wrap gap-7 lg:gap-4 xl:gap-7 mt-8 mb-12">
                        <a className="py-1 px-2 border-1 rounded-full cursor-pointer" aria-label="follow cyber voyce on youtube" href="https://www.youtube.com/channel/UCDZOmnyr7ddOE7lPqhseDiA" target="_blank" rel="noreferrer"> 
                            <YouTubeIcon fontSize="medium" />  
                        </a>

                        {/* <a className="py-1 px-2 border-1 rounded-full flex items-center cursor-pointer" aria-label="be a part of cybervoyce's community on discord" href="https://discord.gg/MXJ2vPYevR" target="_blank" rel="noreferrer">
                            <img src={DiscordIcon} alt="discord link" className="w-6" />
                        </a> */}

                        <a className="py-1 px-2 border-1 rounded-full cursor-pointer" aria-label="follow cybervoyce on facebook" href="https://www.facebook.com/CyberVoyce-109537555171203" target="_blank" rel="noreferrer">
                            <FacebookIcon fontSize="medium"/>
                        </a>
                        <a className="py-1 px-2 border-1 rounded-full flex items-center cursor-pointer" aria-label="keep up with cybervoyce on the x app" href="https://twitter.com/CyberVoyce" target="_blank" rel="noreferrer">
                            <img src={XIcon} alt="X app link" className="w-6"/>
                        </a>
                        <a className="py-1 px-2 border-1 rounded-full cursor-pointer" aria-label="stay up to date with cybervoyce's development on instagram" href="https://www.instagram.com/cyber_voyce/" target="_blank" rel="noreferrer">
                            <InstagramIcon fontSize="medium"/>
                        </a>
                    </div>

                    <div className="lg:flex gap-5 cursor-default">
                        <p className="mb-4">Privacy Policy</p>
                        <p>Terms of Service</p>
                    </div>
                </div>

                <p className="absolute left-4 lg:left-20 xl:left-32 2xl:left-40 bottom-3">© CyberVoyce. All Rights Reserved</p>
            </footer>
    );
}