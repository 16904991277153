import React, { Suspense } from 'react';

// COMPONENTS
const Minting = React.lazy(() => import('../Components/nft/Minting'));
const Streams = React.lazy(() => import('../Components/nft/Streams'));

export default function NFT(props){
    return(
        <>
            {props.Hero}
            {props.Aspect}
            <Suspense>
                <Minting windowWidth={props.windowWidth}/>
                <Streams />
            </Suspense>
        </>
    );
}