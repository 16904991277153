import React from 'react';

export default function MobileCarousel(){
    const images = [
        {
            img: require("../../assets/PX - 6_sponsored post.png"),
            alt: "CyberVoyce post"
        },

        {
            img: require("../../assets/PX - 1_personal profile.png"),
            alt: "CyberVoyce profile page"
        },

        {
            img: require("../../assets/PX - 11_market.png"),
            alt: "CyberVoyce marketplace screen"
        },

        {
            img: require("../../assets/PX - 33_marketplace_product.png"),
            alt: "CyberVoyce NFT purchase screen"
        },

        {
            img: require("../../assets/X - 22 Wallet.png"),
            alt: "CyberVoyce wallet screen"
        },

        {
            img: require("../../assets/PX - 35_Wallet_coin stats.png"),
            alt: "Ethereum market performance screen"
        }
    ];


    return(
        <div className="flex w-full overflow-x-scroll no-scrollbar gap-6 mx-auto max-w-3xl">
            {images.map(image => (
                <img src={image.img} alt={image.alt} className=" w-1/2 max-w-270 rounded-2xl"/>
            ))}
        </div>
    );
}