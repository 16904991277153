import React from 'react';

// ASSETS
import structure from '../../assets/blockchain hero.png';
import swirls from '../../assets/blockchain hero asset.png';
import shadow from '../../assets/hero shadow.png';
import polygon from '../../assets/blurred polygon.png';

export default function Hero(){
    return(
        <section className="h-fit py-16 2xl:py-24 flex relative overflow-hidden">
            <div className="h-fit mx-auto md:w-11/12 lg:max-w-7xl flex flex-col md:flex-row">
                <div className="w-11/12 mx-auto lg:ml-0 md:my-auto mb-7 max-w-md md:max-w-sm lg:max-w-md">
                    <h1 className="text-3xl md:text-4xl xl:text-5xl font-Orbitron font-extraBold mb-4">Cyber Voyce <span className="text-primary-pink">Blockchain</span></h1>
                    <p className="text-lg xl:text-xl font-Inter font-regular">
                        The CyberVoyce blockchain is composed of four chains 
                        that serve different purposes, each optimized for 
                        maximum efficiency and performance.
                    </p>
                </div>

                <div className="relative pb-10 z-10">
                    <img src={polygon} alt="" className="absolute w-48 sm:w-3/4 lg:w-full top-1/4 sm:-top-10 md:top-0 lg:-top-1/4 2xl:top-0 2xl:-bottom-1/3 right-1/4 sm:-right-1/2 md:-right-1/4 lg:-right-1/2 2xl:-right-full z-0 blur-xl sm:blur-2xl lg:opacity-50 2xl:opacity-100 prevent-select"/>
                    <img src={structure} alt="blockchain structure" className="w-11/12 lg:w-full max-w-md md:max-w-sm lg:max-w-lg xl:max-w-xl mx-auto prevent-select"/>
                </div>
            </div>

            <img src={swirls} alt="" className="absolute bottom-0 h-1/2 sm:h-1/4 md:h-1/2 w-full prevent-select"/>
            <img src={shadow} alt="" className="absolute bottom-0 h-28 md:h-1/4 prevent-select"/>
        </section>
    )
}