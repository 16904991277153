import React from 'react';
import {Link} from "react-router-dom";

//ASSETS
import logo from '../assets/CYBERVOYCE full logo.png';
import MenuIcon from '@mui/icons-material/Menu';

export default function Navbar(props){
    const [prevScrollPos, setPrevScrollPos] = React.useState(window.scrollY);
    const [visible, setVisible] = React.useState(true);

    React.useEffect(() => {
        const handleScroll = () => {
          const currentScrollPos = window.scrollY;
          const visible = prevScrollPos > currentScrollPos;
    
          setPrevScrollPos(currentScrollPos);
          setVisible(visible);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [prevScrollPos]);

    return(
      <>
        <nav className={`h-16 md:h-20 w-full fixed opacity-97 flex items-center bg-primary-background z-30 duration-300 overflow-hidden ${visible ? 'top-0' : '-top-20'}`}>
            <div className="h-fit w-11/12 flex items-center mx-auto max-w-screen-2xl prevent-select">
                {props.windowWidth < 640 && <MenuIcon fontSize="medium" className="mr-2" onClick={() => props.setShowMobileNav(true)}/>}
                    <img src={logo} alt="Cybervoyce logo" className="h-6 sm:h-8 md:h-9 lg:h-12"/>

                {/* desktop navbar */}
                {props.windowWidth > 640 &&
                    <div className="flex h-full mx-auto gap-4 md:gap-6 lg:gap-9 font-Inter font-light lg:font-regular md:text-lg lg:mt-auto">
                        <Link
                          to="/"
                          className={`hover:text-primary-pink duration-200 my-auto relative pb-2 ${props.currPage === 1 && 'text-primary-pink partial-border'}`}
                          onClick={() => props.handlePageChange(1)}
                          >Home</Link>
                        <Link
                          to="/blockchain"
                          className={`hover:text-primary-pink duration-200 relative ${props.currPage === 2 && 'text-primary-pink partial-border'}`}
                          onClick={() => props.handlePageChange(2)}
                          >Blockchain</Link>
                        <Link
                          to="/nft"
                          className={`hover:text-primary-pink duration-200 relative ${props.currPage === 3 && 'text-primary-pink partial-border'}`}
                          onClick={() => props.handlePageChange(3)}
                          >NFT</Link>
                    </div>
                }

                {/* whitepaper and beta button */}
                <div className="flex gap-5 ml-auto">
                  { props.windowWidth >= 1024 &&
                    <a 
                      href="https://docs.google.com/document/d/1kEm60cSQpiT0xF2ItQLszIvXWKdYUhiZra8VL_PTWgc/edit?usp=sharing" 
                      target="_blank" 
                      className="bg-gradient-to-r from-primary-pink to-primary-violet px-3 lg:px-4 py-1 lg:py-2 rounded-sm font-Inter font-regular">
                    Read Whitepaper</a>
                  }
                  <a 
                    href="https://presale.cybervoyce.com/" 
                    target="_blank" 
                    className="bg-gradient-to-r from-primary-pink to-primary-violet px-3 lg:px-4 py-1 lg:py-2 rounded-sm font-Inter font-regular">
                  Access Presale</a>
                </div>

            </div>
        </nav>
      </>
    );
}