import React from 'react';

//ASSETS
import guy from '../../assets/image 1250.png';
import love from '../../assets/Love.png';
import like from '../../assets/Like.png';
import group from '../../assets/Group 1261152964.png';
import bubble from '../../assets/Group 39879.png';

export default function HomeSlide3(props){
    return(
        <section className="h-screen sm:h-fit sm:py-12 lg:py-20 2xl:py-24 flex relative">
                <div className="w-11/12 h-full my-auto mx-auto flex flex-col md:flex-row-reverse justify-evenly">
                    <div className="max-w-xl md:max-w-sm xl:max-w-md 2xl:max-w-xl md:h-fit md:ml-10 lg:ml-0 xl:my-auto">
                        <h2 className="font-Orbitron font-extraBold text-3xl lg:text-4xl 2xl:text-5xl lg:leading-tight 2xl:leading-snug mb-4">Your Voyce, No Centralized Agenda, Your <span className="gradient-text">Freedom.</span></h2>
                        <p className="font-Exo font-medium text-lg xl:text-xl">
                            Your Voyce is what matters here. Vote on what you 
                            like and dislike. How the platform should be run. 
                            Reset your algorithm to your original preferences. 
                            You control everything. Finally a media for users by users.
                        </p>
                    </div>

                    <div className="relative bg-home-slide3 bg-cover bg-no-repeat bg-center h-2/5 xl:w-full max-w-md xl:max-w-xl flex mx-auto md:my-auto xl:mx-0 prevent-select">
                        <img src={guy} alt="guy on his phone" className="w-2/3 mx-auto mt-auto" />
                        <img src={bubble} alt="" className="absolute w-1/3 left-0 top-10"/>
                        <img src={like} alt="" className="absolute w-1/5 right-8 top-10"/>
                        <img src={love} alt="" className="absolute w-1/3 right-5 -bottom-10"/>
                        <img src={group} alt="" className="absolute right-0 -top-9 w-24 lg:w-28 xl:w-32"/>
                        {props.windowWidth >= 640 && <img src={group} alt="" className="absolute -left-20 md:-left-16 lg:-left-20 xl:-left-24 bottom-10 lg:bottom-12 w-24 lg:w-28 xl:w-32 -rotate-90"/>}
                    </div>
                </div>
            </section>
    );
}