import React, { Suspense } from 'react';

// COMPONENTS
const HomeSlide4 = React.lazy(() => import('../Components/home/HomeSlide4'));
const CVDemo = React.lazy(() => import('../Components/home/CVDemo'));
const CVDAO = React.lazy(() => import('../Components/home/CVDAO'));
const CVCards = React.lazy(() => import('../Components/home/CVCards'));
const CVCoins = React.lazy(() => import('../Components/home/CVCoins'));
const HomePresale = React.lazy(() => import('../Components/home/HomePresale'));
const OurVision = React.lazy(() => import('../Components/home/OurVision'));
const OurMission = React.lazy(() => import('../Components/home/OurMission'));
const Tokenomics = React.lazy(() => import('../Components/home/Tokenomics'));
const AntiWhale = React.lazy(() => import('../Components/home/AntiWhale'));
const HomeNFTs= React.lazy(() => import('../Components/home/HomeNFTs'));


export default function Home(props){

    return(
        <>
            {props.HomeHero}
            {props.CVIntro}
            {props.HomeSlide3}
            <Suspense>
                <HomeSlide4 windowWidth={props.windowWidth}/>
                {/* <CVDemo windowWidth={props.windowWidth}/> */}
                <CVDAO windowWidth={props.windowWidth}/>
                <CVCards windowWidth={props.windowWidth}/>
                <CVCoins />
                <HomePresale windowWidth={props.windowWidth}/>

                <section className="h-fit pt-16 pb-24 flex flex-col">
                    <Tokenomics />
                    <AntiWhale windowWidth={props.windowWidth}/>
                    <HomeNFTs />
                </section>

                <OurVision />
                <OurMission />
            </Suspense>
        </>
    )
};