import React from 'react';

export default function DesktopCarousel(props){
    const images = [
        {
            img: require("../../assets/PX - 6_sponsored post.png"),
            alt: "CyberVoyce post"
        },

        {
            img: require("../../assets/PX - 1_personal profile.png"),
            alt: "CyberVoyce profile page"
        },

        {
            img: require("../../assets/PX - 11_market.png"),
            alt: "CyberVoyce marketplace screen"
        },

        {
            img: require("../../assets/PX - 33_marketplace_product.png"),
            alt: "CyberVoyce NFT purchase screen"
        },

        {
            img: require("../../assets/X - 22 Wallet.png"),
            alt: "CyberVoyce wallet screen"
        },

        {
            img: require("../../assets/PX - 35_Wallet_coin stats.png"),
            alt: "Ethereum market performance screen"
        }
    ];

    return(
        <div className="h-full w-1/3 2xl:w-1/2 ml-auto overflow-y-scroll 2xl:overflow-y-hidden no-scrollbar prevent-select flex flex-col 2xl:flex-row gap-6 2xl:gap-0 2xl:justify-between">
            { props.windowWidth < 1536 ?
            <>
                {images.map(image => (
                    <img src={image.img} alt={image.alt} className=" rounded-2xl w-3/4 mx-auto"/>
                ))}
            </>
            :
            <>
                <div className="flex flex-col gap-8 overflow-y-scroll no-scrollbar overscroll-contain 2xl:mr-10">
                    {images.slice(0, 3).map(image => (
                        <img src={image.img} alt={image.alt} className=" rounded-2xl w-full"/>
                    ))}
                </div>

                <div className="flex flex-col gap-8 overflow-y-scroll no-scrollbar overscroll-contain">
                    {images.slice(3, 6).map(image => (
                        <img src={image.img} alt={image.alt} className=" rounded-2xl w-full"/>
                    ))}
                </div>
            </>
            }

        </div>
    );
}