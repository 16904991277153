import React from 'react';

// ASSETS
import desktop from '../../assets/marketplace desktop.png';
import mobile from '../../assets/marketplace mobile.png';
import blur from '../../assets/aspect blur.png';

export default function Aspect(){
    return(
        <section className="h-fit py-24 lg:py-32 flex">
            <div className="my-auto md:mx-auto lg:ml-0 flex flex-col lg:flex-row-reverse lg:justify-between lg:w-11/12 2xl:w-10/12">
                <div className="w-11/12 max-w-lg lg:max-w-sm xl:max-w-md mx-auto lg:mx-0 mb-20 lg:my-auto">
                    <h2 className="text-3xl md:text-4xl xl:text-5xl font-Orbitron font-extraBold mb-4">NFT <span className="gradient-text">Aspect</span></h2>
                    <p className="mb-10 font-Exo font-medium text-lg xl:text-xl">
                        Our platform pioneers the concept of 'self-ownership.' Every CyberVoyce 
                        user is not just a member; they are a digital trailblazer, securing their 
                        identity through NFT technology. Your account, your data, and your content 
                        are truly yours, safeguarded on the blockchain.
                        <br />
                        <br />
                        But that's not all. At CyberVoyce, we recognize the value of your creativity. 
                        You can hold, or sell your NFT content within our marketplace. No more worries 
                        about plagiarism or content theft – your creations are protected, giving you 
                        the confidence to express yourself without boundaries.

                    </p>
                    <a href="https://docs.google.com/document/d/1kEm60cSQpiT0xF2ItQLszIvXWKdYUhiZra8VL_PTWgc/edit?usp=sharing" target="_blank" className="font-Inter font-regular text-lg bg-gradient-to-r from-primary-pink to-primary-violet rounded-sm px-4 py-3">Read Whitepaper</a>
                </div>

                <div className="relative flex lg:w-full max-w-4xl 2xl:max-w-5xl xl:mr-auto prevent-select h-fit lg:my-auto">
                    {/* <img src={blur} alt="" className="absolute w-3/4 left-0 bottom-0 md:blur-xl"/> */}
                    <img src={desktop} alt="wallet desktop preview" className="w-10/12 h-auto mx-auto z-10"/>
                    <img src={mobile} alt="wallet mobile preview" className="absolute w-2/5 2xl:w-1/3 left-0 -bottom-10 z-10"/>
                </div>
            </div>
        </section>
    );
}