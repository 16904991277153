import React from 'react';

// ASSETS
import arrows from '../../assets/arrows.png';
import CVHome from '../../assets/CV Home.webp';
import MobileHand from '../../assets/Home mobile.webp';
import logoLeft from '../../assets/home logo left.png';
import logoRight from '../../assets/home logo right.png';
import logo from '../../assets/cv eye.png';
import x from '../../assets/home x.png';

export default function HomeHero(props){
    return(
        <section className="h-screen sm:py-20 sm:h-fit flex relative overflow-hidden">
                {props.windowWidth >= 786 && <img src={x} alt="" className="absolute top-1/3 left-0 lg:left-28 prevent-select"/>}
                {props.windowWidth >= 786 && <img src={x} alt="" className="absolute top-1/4 right-0 lg:right-10 xl:right-32 prevent-select"/>}
                {props.windowWidth >= 786 && <img src={logoLeft} alt="" className="absolute prevent-select"/>}
                {props.windowWidth >= 786 && <img src={logoRight} alt="" className="absolute right-0 top-1/4 prevent-select"/>}

                <div className="flex flex-col h-fit w-full my-auto">
                    <div className="text-center mb-12">
                        <h1 className="font-Orbitron font-bold text-3xl md:text-4xl xl:text-5xl mb-4 lg:mb-7 w-11/12 max-w-2xl lg:max-w-3xl mx-auto">The Future of <span className="gradient-text">Social Media</span> is Finally here.</h1>
                        <p className="font-Exo font-regular text-lg xl:text-xl w-11/12 mx-auto md:max-w-3xl lg:max-w-4xl">
                            Welcome to Cyber Voyce where Web 2 meets Web 3. 
                            Join us in our journey of creating a decentralized 
                            social media that rewards its users! Become an 
                            influencer and take charge in how Cyber Voyce is 
                            run. Finally, a platform for users by users.
                        </p>
                    </div>

                    <div className="w-full relative flex prevent-select">
                        <img src={arrows} alt="arrows" className="absolute top-1/2 lg:top-1/3 w-full max-h-44"/>
                        {props.windowWidth >= 786 && <img src={x} alt="" className="absolute -bottom-14 left-1/2 prevent-select"/>}
                        <div className="max-w-7xl  mx-auto relative z-10">
                        <img src={logo} alt="" className="absolute w-12 -rotate-45 left-3 md:-left-5 -bottom-1/3 sm:-bottom-14 prevent-select"/>
                            <img src={CVHome} alt="cybervoyce home screen" className="w-11/12 xl:w-full ml-3" />
                            <img src={MobileHand} alt="cybervoyce mobile home screen" className="absolute w-1/3 xl:w-1/2 right-0 xl:-right-64 -bottom-5 xl:-bottom-10"/>
                        </div>
                    </div>

                </div>
            </section>
    );
}