import React from 'react';
import {Link} from "react-router-dom";

export default function MobileNav(props){

    const changePage = (index) => {
        props.handlePageChange(index);
        props.setShowMobileNav(false);
    }
    return(
        <>
            <div className={`z-50 w-4/5 h-full flex fixed bg-secondary-bg2 slideInNav ${!props.showMobileNav && '-translate-x-full'}`}>
                <nav className="w-fit h-fit flex flex-col mx-auto mt-32 font-Inter font-regular text-2xl text-center gap-5">
                    <Link
                        to="/"
                        className={`${props.currPage === 1 && 'text-primary-pink'}`}
                        onClick={() => changePage(1)}
                    >Home</Link>
                    <Link
                        to="/blockchain"
                        className={`${props.currPage === 2 && 'text-primary-pink'}`}
                        onClick={() => changePage(2)}
                    >Blockchain</Link>
                    <Link
                        to="/nft"
                        className={`${props.currPage === 3 && 'text-primary-pink'}`}
                        onClick={() => changePage(3)}
                    >NFT</Link>
                </nav>
            </div>

            <div className={`z-40 w-full h-full right-0 fixed modal-bg ${!props.showMobileNav && 'hidden'}`} onClick={() => props.setShowMobileNav(false)}></div>
        </>
    );
}