import React, { Suspense } from 'react';

// COMPONENTS
const Chains = React.lazy(() => import('../Components/blockchain/chains'));
const Wallet = React.lazy(() => import('../Components/blockchain/WalletExchange'));

export default function Blockchain(props){
    return(
        <>
            {props.Slide4}
            {props.Hero}
            <Suspense>
                <Chains windowWidth={props.windowWidth}/>
                <Wallet />
            </Suspense>
        </>
    )
}