import React from 'react';

// ASSETS
import appPreview from '../../assets/desktop phone view 2.png';
import pattern from '../../assets/blockchain pattern.png';

export default function slide4(props){
    return(
        <section className="h-fit xl:h-screen py-16 xl:py-0 relative flex overflow-hidden">
                <div className="my-auto md:mx-auto flex flex-col xl:flex-row-reverse xl:w-full xl:justify-evenly">
                    <div className="w-11/12 max-w-lg 2xl:max-w-xl mx-auto  mb-14">
                        <h2 className="text-3xl md:text-4xl xl:text-5xl font-Orbitron font-extraBold mb-4">Blockchain <span className="text-primary-pink">Integration</span> with Social Media</h2>
                        <p className="text-lg xl:text-xl font-Exo font-medium mb-8">
                            The CyberVoyce blockchain is designed to be integrated with social media platforms,
                            creating a new type of social media experience that is faster, more secure, and more
                            transparent. By leveraging the power of blockchain technology, we can create a 
                            decentralized social media platform that is not controlled by any central authority
                            and is more resistant to censorship.
                        </p>

                        <a href="https://docs.google.com/document/d/1kEm60cSQpiT0xF2ItQLszIvXWKdYUhiZra8VL_PTWgc/edit?usp=sharing" target="_blank" className="font-Inter font-regular text-lg bg-gradient-to-r from-primary-pink to-primary-violet rounded-sm px-4 py-2">Read Whitepaper</a>
                    </div>

                    <div className="w-11/12 lg:w-full xl:max-w-2xl 2xl:max-w-4xl flex z-10 prevent-select">
                        <img src={appPreview} alt="cybervoyce app preview" className="w-full my-auto"/>
                    </div>
                </div>

                {props.windowWidth >= 1280 && <img src={pattern} alt="" className="absolute 2xl:w-2/5 left-0 top-0 z-0 prevent-select"/>}
            </section>
    );
}