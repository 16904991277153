import React from 'react';

// ASSETS
import nft1 from '../../assets/nft 1.png';
import nft2 from '../../assets/nft 2.png';
import diamond from '../../assets/nft hero asset.png';
import blur from '../../assets/nft hero Polygon.png';
import arrow from '../../assets/nft hero arrow.png';


export default function Hero(props){
    return(
        <section className="h-fit pt-16 pb-28 sm:pb-36 xl:pb-40 flex">
            <div className="lg:w-full max-w-7xl flex flex-col lg:flex-row my-auto mx-auto">
                <div className="w-11/12 h-fit lg:w-full max-w-md lg:max-w-sm xl:max-w-lg mx-auto mb-10 md:mb-16 lg:mb-0 lg:mt-16 relative">
                    <h1 className="text-3xl md:text-4xl xl:text-5xl font-Orbitron font-semiBold mb-4">Buy, Sell, and Trade <span className="text-primary-pink">NFTs</span> with Confidence</h1>
                    <p className="font-Exo font-medium text-2xl">
                        Buy, sell, and explore tokens and NFTs
                    </p>
                    {props.windowWidth >= 1024 && <img src={arrow} alt="" className="absolute -right-10 -bottom-5 xl:-bottom-8 prevent-select"/>}
                </div>

                <div className="w-11/12 sm:w-full lg:w-1/2 max-w-2xl grid grid-cols-2 mx-auto relative">
                    <div className=" w-11/12 z-10">
                        <img src={nft1} alt="nft example" className="w-full prevent-select"/>
                    </div>

                    <div className=" w-11/12 relative z-10">
                        <img src={nft2} alt="nft example" className="w-full absolute top-1/3 prevent-select"/>
                    </div>
                    <img src={diamond} alt="" className="absolute w-24 right-12 sm:right-28 lg:right-20 -top-10 sm:top-0 lg:top-0 prevent-select"/>
                    <img src={blur} alt="" className="absolute left-0 -top-1/4 z-0 blur-xl prevent-select"/>
                </div>
            </div>
        </section>
    );
}