import React from 'react';

// COMPONENTS
import MobileCarousel from '../home/MobileCarousel';
import DesktopCarousel from '../home/DesktopCarousel';

// ASSETS
import exes from '../../assets/Group 1.png';
import pattern from '../../assets/Pattern.png';

export default function CVIntro(props){
    return(
        <section className="h-fit lg:h-screen pt-16 pb-8 sm:pt-14 sm:pb-5 lg:p-0 bg-home-slide2 bg-cover bg-no-repeat bg-center flex relative">
                <img src={exes} alt="exes" className="absolute left-5 top-5 w-24 z-0 prevent-select"/>
                <div className="h-fit lg:h-full w-11/12 mt-auto lg:mb-auto mx-auto z-10 flex flex-col lg:flex-row 2xl:w-10/12">
                    <div className="mb-6 lg:mb-auto lg:mt-20 xl:mt-32 2xl:mt-60 lg:h-fit mx-auto 2xl:mx-0">
                        <h2 className="font-Orbitron font-extraBold xl:leading-tight text-3xl md:text-4xl xl:text-5xl mb-5 lg:mb-7 max-w-xl md:max-w-2xl lg:max-w-lg xl:max-w-2xl 2xl:max-w-3xl">Introducing CyberVoyce: Your Unrestricted Social Media Platform.</h2>
                        <p className="font-Exo font-medium text-lg xl:text-xl w-full lg:max-w-xl xl:max-w-2xl">
                            CyberVoyce is a groundbreaking social media platform that puts 
                            the power back into your hands. We believe in ownership. With us, 
                            your account isn't just a profile; it's an NFT, a unique and 
                            irreplaceable token that represents you in the digital world. 
                            Imagine having complete control over your online identity, where 
                            every post, every comment, every interaction is a testament to 
                            your individuality and creativity.
                        </p>
                    </div>

                    {props.windowWidth < 1024 ? 
                        <MobileCarousel />
                    :
                        <DesktopCarousel windowWidth={props.windowWidth}/>
                    }

                </div>
                {props.windowWidth >= 1024 && <img src={pattern} alt="pattern" className="absolute left-16 2xl:left-28 bottom-7 2xl:bottom-12 w-48 xl:w-56 2xl:w-64 z-0 prevent-select"/>}


            </section>
    );
}